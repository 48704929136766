import { Button, Divider, Form, Row, Table, message } from "antd";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useState } from "react";
import PageCard from "./PageCard";

const pageSize = 20;

const ObjectsTable = ({title, getMethod, formFields, columns, DetailsCard, headerExtra, client}) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [state, setState] = useState({
        loaded: false,
        data: [],
        query: {},
        details: {
            open: false,
            object: null,
        },
        pagination: {
            current: 0,
            canForward: true,
            canBackward: false
        }
    });

    const changePage = (dir) => {
        setState({...state, loaded:false, data:[], pagination: {...state.pagination, current: state.pagination.current+dir}})
    }

    if (!state.loaded) {
        const q = state.query;
        q.limit = pageSize;
        q.offset = state.pagination.current * pageSize;
        getMethod(
            q,
            (objects)=>{
                const newState = {...state, loaded: true, data: objects};
                newState.pagination.canBackward = state.pagination.current > 1
                newState.pagination.canForward = objects.length >= pageSize;
                setState(newState);
            },
            () => {
                messageApi.open({
                    type: 'error',
                    content: 'Failed to load',
                });
            },
        )
    }

    // setup form
    let form;
    let formDivider;
    if (formFields) {
        form = (
            <Form
                layout="inline"
                onFinish={(q) => {
                    setState({...state, loaded: false, data: [],  pagination: {...state.pagination, current:0}, query: q})
                }}
            >
                {formFields}
                <Form.Item>
                    <Button
                        htmlType="reset"
                        onClick={() => {
                            setState({...state, loaded:false, data:[], pagination: {...state.pagination, current:0}, query: {}});
                        }}
                    >
                    Clean
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Search</Button>
                </Form.Item>
            </Form>
        );
        formDivider = <Divider />
    }

    // setup details
    let objectDetails;
    if (DetailsCard && state.details.open) {
        objectDetails = (
            <DetailsCard
                open={true}
                obj={state.details.object}
                client={client}
                onClose={()=>{
                    setState({...state, details: {open: false, object: null}});
                }}
            />
        )
    }
    return (
        <PageCard title={title || ""} extra={headerExtra}>
            {contextHolder}
            {form}
            {formDivider}
            <Table
                columns={columns}
                dataSource={state.data}
                tableLayout="auto"
                rowKey="id"
                pagination={false}
                loading={!state.loaded}
                onRow={(object) => {
                    return {
                      onClick: (event) => {
                        setState({...state, details: {open:true, object: object}});
                      },
                    };
                  }}
            />
            {objectDetails}
            <Row justify="end" style={{paddingTop: "10px"}}>
                <Button disabled={!state.pagination.canBackward} onClick={() => {changePage(-1)}}><LeftOutlined /></Button>
                <Button disabled={!state.pagination.canForward} onClick={() => {changePage(1)}}><RightOutlined /></Button>
            </Row>
        </PageCard>
    )
};

export default ObjectsTable;

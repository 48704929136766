import { Button, Descriptions, Drawer, Form, Input, Modal, Space, Tag,message } from "antd";
import { TwitterOutlined } from '@ant-design/icons';
import ObjectsTable from "../components/ObjectsTable";
import RewardsConfigBlock from "../components/RewardsConfigBlock";
import { TelegramIcon } from "../components/icons";
import { GetTelegramLink, GetTwitterLink } from "../utils/socialsLinks";
import { useState } from "react";
import TextArea from "antd/es/input/TextArea";

const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Account',
      dataIndex: 'account_id',
      key: 'account_id',
    },
    {
      title: 'Campaign',
      dataIndex: ['campaign', 'name'],
      key: 'campaign.name',
    },
    {
        title: "Tags",
        key:"tags",
        render: (obj) => {
            return <ParticipantTags obj={obj}/>
        }
    },
    {
        title: 'Created',
        dataIndex: 'created',
        key: 'created',
        render: (created) => {
            var date = new Date(created * 1000);
            return date.toLocaleString();
        },
    },
]

const ParticipantTags = ({obj}) => {
    const tags = [];
    if (obj.is_virtual) {
        tags.push(<Tag key="virtual" color="blue">Virtual</Tag>);
    }
    if (obj.is_influencer) {
        tags.push(<Tag key="influencer" color="green">Influencer</Tag>);
    }
    return tags;

};

const ParticipantSocials = ({socials}) => {
    if (!socials) {
        return
    }
    return (
        <Space wrap>
            {
                socials.map((s, idx) =>{
                    let icon;
                    let link;
                    if (s.type === "telegram")  {
                            link = GetTelegramLink(s.username)
                            icon = <TelegramIcon/>;
                    } else if (s.type === "twitter") {
                            link = GetTwitterLink(s.username)
                            icon = <TwitterOutlined />;
                    }
                    if (link) {
                        return (
                            <a href={link} key={idx} target="_blank" rel="noreferrer">
                                <Tag  id={s.type} icon={icon}>{s.username}</Tag>
                            </a>
                        );
                    } else {
                        return (
                            <Tag key={idx} id={s.type} icon={icon}>{s.username}</Tag>
                        );
                    }
                })
            }
        </Space>
    )
}

const ParticipantDetails = ({open, onClose, obj}) => {
    let description;
    if (obj !== null) {
        description = (
            <Descriptions column={1} bordered>
                <Descriptions.Item label="ID">{obj.id}</Descriptions.Item>
                <Descriptions.Item label="Account">{obj.account_id}</Descriptions.Item>
                <Descriptions.Item label="Network">{obj.network}</Descriptions.Item>
                <Descriptions.Item label="Campaign">{obj.campaign.name}</Descriptions.Item>
                <Descriptions.Item label="Project">{obj.campaign.project_id}</Descriptions.Item>
                <Descriptions.Item label="Tags">
                    <ParticipantTags obj={obj}/>
                </Descriptions.Item>
                <Descriptions.Item label="Socials">
                    <ParticipantSocials socials={obj.socials}/>
                </Descriptions.Item>
                <Descriptions.Item label="Ref code">{obj.ref_code}</Descriptions.Item>
                <Descriptions.Item label="Inviter">{obj.inviter}</Descriptions.Item>
                {
                    Object.entries(obj.custom_rewards || {}).map(([k, v])=> {
                        return (
                            <Descriptions.Item key={"rewards"+k} label={k.charAt(0).toUpperCase() + k.slice(1) + " rewards"}>
                                <RewardsConfigBlock rewards={v}></RewardsConfigBlock>
                            </Descriptions.Item>
                        )
                    })
                }
            </Descriptions>
        );
    }
    return (
        <Drawer
            title="Participant"
            placement={window.innerHeight>window.innerWidth ? "bottom" : "right"}
            closable={true}
            onClose={onClose}
            open={open}
            height="90vh"
            width="50vw"
        >
            {description}
        </Drawer>
    );
};

const ParticipantsList = (props) => {

    return (
        <ObjectsTable
            title="Participants"
            columns={columns}
            DetailsCard={ParticipantDetails}
            headerExtra={<ParticipantApproveButton client={props.client}/>}
            formFields={(
                <Space>
                    <Form.Item name="account_id">
                        <Input placeholder="Account"></Input>
                    </Form.Item>
                    <Form.Item  name="ref_code">
                        <Input placeholder="Ref code"></Input>
                    </Form.Item>
                    <Form.Item name="id">
                        <Input placeholder="ID"></Input>
                    </Form.Item>
                </Space>
            )}
            getMethod={(query, success, error) => {
                props.client.getParticipants(query, (data) => {success(data.participants)}, error)
            }}
        />
    )
};

const ParticipantApproveButton = ({client}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    const approve = (values) => {
        const ids = values.user_ids.split("\n");
        client.approveParticipants(
            ids,
            (data) => {
                if (!data.missing) {
                    form.setFieldValue("user_ids", "");
                    setModalOpen(false);
                    messageApi.open({
                        type: 'success',
                        content: 'Approved',
                    });
                    return;
                }
                form.setFieldValue("user_ids", data.missing.join("\n"));
                messageApi.open({
                    type: 'warning',
                    content: 'Invalid IDs left in field',
                });
            },
            () => {
                messageApi.open({
                    type: 'error',
                    content: 'Failed to send request',
                });
            }
        )
    }
    return (
        <>
        {contextHolder}
        <Button onClick={() => setModalOpen(true)}>
            Approve
        </Button>
        <Modal
            title="Participants approval"
            centered
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            destroyOnClose={true}
            footer={null}
        >
            <Form
                name="approval"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                // style={{ maxWidth: 600 }}
                onFinish={approve}
                form={form}
            >
                <Form.Item
                label="UserIDs"
                name="user_ids"
                rules={[{ required: true, message: 'Please input your some IDs!' }]}
                >
                <TextArea rows={20} placeholder="each id should be on separate line" />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                    Approve
                </Button>
                </Form.Item>
            </Form>
        </Modal>
      </>
    )
}

export default ParticipantsList;
